<template>
  <div class="home">
    <img alt="Vue logo" src="@/assets/logo.png">
    token: {{getToken}}
    <el-button @click="login">登录</el-button>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  name: 'Home',
  components: {
  },
  data () {
    return {}
  },
  computed: {
    ...mapState('user', {token: 'token'}),
    ...mapGetters('user', {getToken: 'getToken'})
  },
  created () {
    // console.log(this.token)
    this.setToken('456')
    // console.log(this.getToken)

    this.loginToken({name: 'a', pass: 'b1'}).then(res => {
      // console.log('登录成功:', res)
    }).catch(err => {
      // console.log('登录失败:', err)
    })
  },
  mounted () {
    this.$axios.get('/list').then(res => {
      // console.log(res)
    }).catch(err => {
      // console.log(err)
    })
  },
  methods: {
    ...mapActions('user', {loginToken: 'loginToken'}),
    ...mapMutations('user', {setToken: 'setToken'}),
    login () {
      this.loginToken({name: 'a', pass: 'b'}).then(res => {
        // console.log('登录成功:', res)
        this.$router.replace({ name: 'home' })
      }).catch(err => {
        // console.log('登录失败:', err)
      })
    }
  }
}
</script>

<style lang="less" scoped>
</style>