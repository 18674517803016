import { render, staticRenderFns } from "./lawsuit-card.vue?vue&type=template&id=274be9f8&scoped=true&"
import script from "./lawsuit-card.vue?vue&type=script&lang=js&"
export * from "./lawsuit-card.vue?vue&type=script&lang=js&"
import style0 from "./lawsuit-card.vue?vue&type=style&index=0&id=274be9f8&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "274be9f8",
  null
  
)

export default component.exports