<template>
    <div class="lawsuit-card-container">

        <div style="display: flex;align-items: center;">
            <p style="font-size: 14px;color: #000;font-weight:600;padding:0 10px">行政许可 {{xzxkTotal}}</p>
        </div>
        <template>
            <el-table :data="administrationList" :header-cell-style="{background:'rgba(18,139,237,0.10)'}"
                      style="width: 100%">
                <el-table-column align="center" label="序号" type="index">
                </el-table-column>
                <el-table-column align="center" label="行政许可类别" prop="xk_XKLB">
                </el-table-column>
                <el-table-column align="center" label="许可证书名称" prop="xk_XKZS">
                </el-table-column>
                <el-table-column align="center" label="行政许可编号" prop="xk_XKBH">
                </el-table-column>
                <el-table-column align="center" label="行政许可内容" min-width="200" prop="xk_NR">
                </el-table-column>
                <el-table-column align="center" label="决定日期" prop="xk_JDRQ">
                </el-table-column>
<!--                <el-table-column align="center" label="有效期" prop="youxiaoqi">-->
<!--                </el-table-column>-->
                <el-table-column align="center" label="状态" prop="xk_ZT">
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="handlexzxkSizeChange"
                    @current-change="handlexzxkCurrentChange"
                    :current-page="xzxkPageNo"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="xzxkPagesize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="xzxkTotal">
            </el-pagination>
        </template>


        <!-- <div style="display: flex;align-items: center;">
            <p style="font-size: 14px;color: #000;font-weight:600;padding:0 10px">经营状况 {{produce.length}}</p>
        </div>
        <template>
            <el-table :data="produce" :header-cell-style="{background:'rgba(18,139,237,0.10)'}"
                      style="width: 100%">
                <el-table-column align="center" label="序号" type="index">
                </el-table-column>
                <el-table-column align="center" label="企业名称" prop="XY010101">
                </el-table-column>
                <el-table-column align="center" label="营业总收入(万元)" prop="XY023501">
                </el-table-column>
                <el-table-column align="center" label="主营业收入(万元)" prop="XY023502">
                </el-table-column>
                <el-table-column align="center" label="资产总额(万元)" prop="XY023503">
                </el-table-column>
                <el-table-column align="center" label="纳税总额(万元)" prop="XY023504">
                </el-table-column>
                <el-table-column align="center" label="所有者权益合计(万元)" prop="XY023505">
                </el-table-column>
                <el-table-column align="center" label="利润总额(万元)" prop="XY023506">
                </el-table-column>
                <el-table-column align="center" label="净利润(万元)" prop="XY023507">
                </el-table-column>
                <el-table-column align="center" label="负债总额(万元)" prop="XY023508">
                </el-table-column>
                <el-table-column align="center" label="年度" prop="XY023509">
                </el-table-column>
            </el-table>
        </template> -->

        <div style="display: flex;align-items: center;">
            <p style="font-size: 14px;color: #000;font-weight:600;padding:0 10px">双随机抽查 {{randomTotal}}</p>
        </div>
        <template>
            <el-table :data="random" :header-cell-style="{background:'rgba(18,139,237,0.10)'}"
                      style="width: 100%">
                <el-table-column align="center" label="序号" type="index">
                </el-table-column>
                <el-table-column align="center" label="任务编号" prop="">
                </el-table-column>
                <el-table-column align="center" label="任务名称" prop="XY024018">
                </el-table-column>
                <el-table-column align="center" label="抽查机关" prop="XY024014">
                </el-table-column>
                <el-table-column align="center" label="完成日期" prop="XY024016">
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="handleRandomSizeChange"
                    @current-change="handleRandomCurrentChange"
                    :current-page="randomPageNo"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="randomPagesize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="randomTotal">
            </el-pagination>
        </template>


        <div style="display: flex;align-items: center;">
            <p style="font-size: 14px;color: #000;font-weight:600;padding:0 10px">招投标 {{BiddingList.length}}</p>
        </div>
        <template>
            <el-table :data="BiddingList" :header-cell-style="{background:'rgba(18,139,237,0.10)'}"
                      style="width: 100%">
                <el-table-column align="center" label="序号" type="index">
                </el-table-column>
                <el-table-column align="center" label="招标名称" prop="">
                </el-table-column>
                <el-table-column align="center" label="代理单位" prop="">
                </el-table-column>
                <el-table-column align="center" label="中标单位" prop="XY024014">
                </el-table-column>
                <el-table-column align="center" label="联系方式" prop="XY024016">
                </el-table-column>
                <el-table-column align="center" label="标书内容" prop="XY024016">
                </el-table-column>
            </el-table>
        </template>


        <div style="display: flex;align-items: center;">
            <p style="font-size: 14px;color: #000;font-weight:600;padding:0 10px">进出口信用 {{exportList.length}}</p>
        </div>
        <template>
            <el-table :data="exportList" :header-cell-style="{background:'rgba(18,139,237,0.10)'}"
                      style="width: 100%">
                <el-table-column align="center" label="序号" type="index">
                </el-table-column>
                <el-table-column align="center" label="海关注册号" prop="">
                </el-table-column>
                <el-table-column align="center" label="注册海关" prop="">
                </el-table-column>
                <el-table-column align="center" label="行政区划" prop="">
                </el-table-column>
                <el-table-column align="center" label="经济区划" prop="">
                </el-table-column>
                <el-table-column align="center" label="经营类别" prop="">
                </el-table-column>
                <el-table-column align="center" label="特殊贸易区域" prop="">
                </el-table-column>
                <el-table-column align="center" label="海关注销标志" prop="">
                </el-table-column>
                <el-table-column align="center" label="年报情况" prop="">
                </el-table-column>
                <el-table-column align="center" label="注册日期" prop="">
                </el-table-column>
                <el-table-column align="center" label="报关有限期" prop="">
                </el-table-column>
                <el-table-column align="center" label="行业种类" prop="">
                </el-table-column>
                <el-table-column align="center" label="信用等级" prop="">
                </el-table-column>

            </el-table>
        </template>
    </div>
</template>

<script>
export default {
    name: 'management-info-tables',
    props: {
        sendInfo: Object
    },
    data() {
        return {
            xzxkPageNo:1,
            xzxkPagesize: 10,
            xzxkTotal:0,

            randomPageNo:1,
            randomPagesize: 10,
            randomTotal: 0,

            info: {},
            canList: [],
            produce: [],
            random:[],
            administrationList:[],
            BiddingList:[],
            exportList:[]
        }
    },
    created() {
        this.getInfo()
        // this.getCanList()
        // this.getProduceList()
        this.getRandomList()
        this.getXyzFxzxk()
    },
    methods: {
        handlexzxkSizeChange(res){
            this.xzxkPagesize = res
            this.getXyzFxzxk();
        },
        handlexzxkCurrentChange(res){
            this.xzxkPageNo =res
            this.getXyzFxzxk();
        },

        handleRandomSizeChange(res){
            this.randomPagesize = res
            this.getRandomList();
        },
        handleRandomCurrentChange(res){
            this.randomPageNo =res
            this.getRandomList();
        },

        getXyzFxzxk(){//获取行政许可信息
            let data = {
                uniscId:this.info.XY010133,
                pageNo:this.xzxkPageNo,
                pageSize:this.xzxkPagesize
            }

            this.$axios.post('/bank/xyzFxzxk', data).then((res) => {
                this.administrationList = res.data.data.rows
                this.xzxkTotal = res.data.data.total
            })
        },
        getInfo() {
            this.info = this.sendInfo
            // console.log(this.info)
        },
        //行政许可
        // getCanList(){
        //     let data =  {
        //         socialCreditCode:this.info.XY010133
        //     }
        //     this.$axios.post('/search/GB0237',data).then(res=>{
        //         // console.log(res)
        //         if(res.data.code == 200){
        //             this.canList = res.data.data.data
        //             console.log(this.canList)
        //         }
        //     })
        // }
        // getProduceList() {
        //     let data = {
        //         socialCreditCode: this.info.XY010133
        //     }
        //     this.$axios.post('/search/GB0235', data).then(res => {
        //         // console.log(res)
        //         if (res.data.code == 200) {
        //             this.produce = res.data.data.data
        //             console.log(this.produce)
        //         }
        //     })
        // },
        getRandomList(){
            let data = {
                socialCreditCode: this.info.XY010133,
                pageNo:this.randomPageNo,
                pageSize:this.randomPagesize
            }
            this.$axios.post('/search/GB0240', data).then(res => {
                // console.log(res)
                if (res.data.code == 200) {
                    this.random = res.data.data.data
                    this.randomTotal = res.data.data.total
                }
            })
        }


    }
}
</script>


<style lang="less" scoped>
.lawsuit-card-container {
    width: 100%;
    height: 100%;

    .lawsuit-card-list {
        display: flex;
        align-items: center;

        span {
            padding: 11px 20px;
            background: #ffffff;
            border: 1px solid #e9e9e9;

            &:not(:first-child) {
                margin-left: 8px;
            }
        }
    }

    .lawsuit-card-table {
        width: 100%;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #424242;
        letter-spacing: 0;
        font-weight: 400;
        border-spacing: 0;

        th,
        td {
            padding: 14px 16px;
        }
    }
}
</style>
