<template>
    <div class="lawsuit-card-container">
        <div style="display: flex;align-items: center;">
            <p style="font-size: 14px;color: #000;font-weight:600;padding:0 10px">失信信息 {{unpromising.length}}</p>
        </div>
        <template>
            <el-table :data="unpromising" :header-cell-style="{background:'rgba(18,139,237,0.10)'}" style="width: 100%">
                <el-table-column align="center" label="序号" type="index"></el-table-column>
                <el-table-column align="center" label="案号" prop="anhao"></el-table-column>
                <el-table-column align="center" label="法定代表人或负责人姓名" prop="fadingdaibiaoren"></el-table-column>
                <el-table-column align="center" label="被执行事件" prop="jutiqingxing"></el-table-column>
                <el-table-column align="center" label="被执行时间" prop="fabushijian"></el-table-column>
                <el-table-column align="center" label="执行法院" prop="zhixingfayuan"></el-table-column>
                <el-table-column align="center" label="法律生效文书" prop="yijuwenhao"></el-table-column>
                <el-table-column align="center" label="履行情况" prop="lvxingqingkuang"></el-table-column>
            </el-table>
        </template>

        <div style="display: flex;align-items: center;">
            <p style="font-size: 14px;color: #000;font-weight:600;padding:0 10px">开庭公告 {{announcementList.length}}</p>
        </div>
        <template>
            <el-table :data="announcementList" :header-cell-style="{background:'rgba(18,139,237,0.10)'}" style="width: 100%">
                <el-table-column align="center" label="序号" type="index"></el-table-column>
                <el-table-column align="center" label="案号" prop=""></el-table-column>
                <el-table-column align="center" label="开庭时间" prop=""></el-table-column>
                <el-table-column align="center" label="案由" prop=""></el-table-column>
                <el-table-column align="center" label="被告" prop=""></el-table-column>
                <el-table-column align="center" label="原告" prop=""></el-table-column>
            </el-table>
        </template>
<!--        <div style="display: flex;align-items: center;">-->
<!--            <p style="font-size: 14px;color: #000;font-weight:600;padding:0 10px">破产信息 {{tankList.length}}</p>-->
<!--        </div>-->
<!--        <template>-->
<!--            <el-table :data="tankList" v-loading="pcxxLoading" :header-cell-style="{background:'rgba(18,139,237,0.10)'}" style="width: 100%">-->
<!--                <el-table-column align="center" label="序号" type="index"></el-table-column>-->
<!--                <el-table-column align="center" label="统一社会信用代码" prop="uniscId"></el-table-column>-->
<!--                <el-table-column align="center" label="案号" prop="caseNumber"></el-table-column>-->
<!--                <el-table-column align="center" label="破产类型" prop="type"></el-table-column>-->
<!--                <el-table-column align="center" label="受理时间" prop="acceptanceTime"></el-table-column>-->
<!--                <el-table-column align="center" label="管理人名称" prop="managerName"></el-table-column>-->
<!--                <el-table-column align="center" label="结案方式" prop="closingMethod"></el-table-column>-->
<!--                <el-table-column align="center" label="结案日期" prop="closingTime"></el-table-column>-->
<!--            </el-table>-->
<!--        </template>-->

        <div style="display: flex;align-items: center;">
            <p style="font-size: 14px;color: #000;font-weight:600;padding:0 10px">裁判文书 {{refereeList.length}}</p>
        </div>
        <template>
            <el-table :data="refereeList" v-loading="cpwsLoading" :header-cell-style="{background:'rgba(18,139,237,0.10)'}" style="width: 100%">
                <el-table-column align="center" label="序号" type="index"></el-table-column>
                <el-table-column align="center" label="标题" prop="biaoti"></el-table-column>
                <el-table-column align="center" label="案号" prop="anhao"></el-table-column>
                <el-table-column align="center" label="案由" prop="anyou"></el-table-column>
                <el-table-column align="center" label="诉讼地位" prop="susongdiwei"></el-table-column>
                <el-table-column align="center" label="法院名称" prop="court"></el-table-column>
                <el-table-column align="center" label="判决结果" prop="result"></el-table-column>
                <el-table-column align="center" label="裁判日期" prop="panjueriqi"></el-table-column>
            </el-table>
        </template>

        <div style="display: flex;align-items: center;">
            <p style="font-size: 14px;color: #000;font-weight:600;padding:0 10px">被执行人 {{ExecuteeList.length}}</p>
        </div>
        <template>
            <el-table :data="ExecuteeList" :header-cell-style="{background:'rgba(18,139,237,0.10)'}" style="width: 100%">
                <el-table-column align="center" label="序号" type="index"></el-table-column>
                <el-table-column align="center" label="案号" prop=""></el-table-column>
                <el-table-column align="center" label="标的金额" prop=""></el-table-column>
                <el-table-column align="center" label="被执行人" prop=""></el-table-column>
                <el-table-column align="center" label="申请执行人" prop=""></el-table-column>
                <el-table-column align="center" label="执行法院" prop=""></el-table-column>
                <el-table-column align="center" label="立案日期" prop=""></el-table-column>
            </el-table>
        </template>

        <div style="display: flex;align-items: center;">
            <p style="font-size: 14px;color: #000;font-weight:600;padding:0 10px">法院公告 {{courtList.length}}</p>
        </div>
        <template>
            <el-table :data="courtList" :header-cell-style="{background:'rgba(18,139,237,0.10)'}" style="width: 100%">
                <el-table-column align="center" label="序号" type="index"></el-table-column>
                <el-table-column align="center" label="上诉人/原告" prop=""></el-table-column>
                <el-table-column align="center" label="被上诉人/被告" prop=""></el-table-column>
                <el-table-column align="center" label="案号" prop=""></el-table-column>
                <el-table-column align="center" label="案由" prop=""></el-table-column>
                <el-table-column align="center" label="公告人" prop=""></el-table-column>
                <el-table-column align="center" label="刊登日期" prop=""></el-table-column>
            </el-table>
        </template>

    </div>
</template>

<script>
export default {
    name: 'lawsuit-card',
    props: {
        sendInfo: Object
    },
    data() {
        return {
            cpwsLoading: false,
            // pcxxLoading: false,
            info: {},
            unpromising: [],
            announcementList:[],
            refereeList:[],
            tankList:[],
            ExecuteeList:[],
            courtList:[]
        }
    },
    created() {
        this.getInfo()
        this.getUnpromisingList()
        this.getRefereeList()
        // this.getTankList()
    },
    methods: {
        getInfo() {
            this.info = this.sendInfo
            console.log(this.info)
        },
        getUnpromisingList() {
            let data = {
                XY010133: this.info.XY010133
            }
            this.$axios.post('/bank/OUTER_SHIXIN', data).then(res => {
                // console.log(res)
                if (res.data.code == 200) {
                    this.unpromising = res.data.data.rows
                }else {
                    this.unpromising = []
                }
            })
        },

        //裁判文书
        getRefereeList() {
            // console.log(this.info)
            let data = {
                // enterpriseName:this.info.XY010101,
                XY010133:this.info.XY010133
            }

            this.cpwsLoading = true;
            this.$axios.post('/bank/OUTER_CPWS', data).then(res => {
                // console.log(res)
                this.cpwsLoading = false
                if (res.data.code == 200) {
                    if(res.data.data.rows){
                        this.refereeList = res.data.data.rows
                    }else {
                        this.refereeList = []
                    }
                }else{
                    this.refereeList = []
                }

            })
        },
        //破产信息
        // getTankList() {
        //     console.log(this.info)
        //     let data = {
        //         enterpriseName:this.info.XY010101,
        //         uniscId:this.info.XY010133
        //     }
        //     this.pcxxLoading = true;
        //     this.$axios.post('/bank/qxrPcxx', data).then(res => {
        //         // console.log(res)
        //         this.pcxxLoading = false;
        //         if (res.data.code == 200) {
        //             if(res.data.data.rows){
        //                 this.tankList = res.data.data.rows
        //             }else {
        //                 this.tankList = []
        //             }
        //         }else {
        //             this.tankList = []
        //         }
        //
        //     })
        // },

    }
}
</script>


<style lang="less" scoped>
.lawsuit-card-container {
    width: 100%;
    height: 100%;

    .lawsuit-card-list {
        display: flex;
        align-items: center;

        span {
            padding: 11px 20px;
            background: #ffffff;
            border: 1px solid #e9e9e9;

            &:not(:first-child) {
                margin-left: 8px;
            }
        }
    }

    .lawsuit-card-table {
        width: 100%;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #424242;
        letter-spacing: 0;
        font-weight: 400;
        border-spacing: 0;

        th,
        td {
            padding: 14px 16px;
        }
    }
}
</style>
