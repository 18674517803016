<template>
  <div class="lawsuit-card-container">
    <div style="display: flex; flex-direction: row">
      <a class="menu-item" href="#stockholder">股东信息</a>
      <a class="menu-item" href="#keyPerson">主要人员</a>
      <a class="menu-item" href="#business">营业执照</a>
      <a class="menu-item" href="#change">变更记录</a>
    </div>
    <div id="business">
      <p class="title-text">营业执照信息</p>
      <el-descriptions :column="3" border label-class-name="my-label">
        <el-descriptions-item label="统一社会信用代码">{{
          info.XY010133
        }}</el-descriptions-item>
        <el-descriptions-item :span="2" label="企业名称">{{
          info.XY010101
        }}</el-descriptions-item>
        <el-descriptions-item label="法定代表人">
          <div class="legal-person-wrapper">
            <div class="yellow-color">{{ xing }}</div>
            <div class="legal-person-name">
              <p>{{ info.XY010105 }}</p>
              <p class="person-position">{{ info.XY010110 }}</p>
            </div>
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="登记状态"
          ><el-tag size="small">{{
            info.XY010132
          }}</el-tag></el-descriptions-item
        >
        <el-descriptions-item label="成立日期">{{
          info.XY010117
        }}</el-descriptions-item>
        <el-descriptions-item v-if="info.XY010112" label="注册资本"
          >{{ info.XY010113 }}万{{ info.XY010112 }}</el-descriptions-item
        >
        <el-descriptions-item v-else label="注册资本"
          >{{ info.XY010113 }}万元人民币</el-descriptions-item
        >
        <el-descriptions-item v-if="info.XY010112" label="实缴资本"
          >{{ info.XY010113 }}万{{ info.XY010112 }}</el-descriptions-item
        >
        <el-descriptions-item v-else label="实缴资本"
          >{{ info.XY010113 }}万元人民币</el-descriptions-item
        >
        <el-descriptions-item label="核准日期">{{
          info.XY010134
        }}</el-descriptions-item>
        <el-descriptions-item label="组织机构代码">{{
          info.XY010501 ? info.XY010501 : "--"
        }}</el-descriptions-item>
        <el-descriptions-item label="工商注册号">{{
          info.XY010102 ? info.XY010102 : "--"
        }}</el-descriptions-item>
        <el-descriptions-item label="纳税人识别号">{{
          info.XY010133 ? info.XY010133 : "--"
        }}</el-descriptions-item>
        <el-descriptions-item label="企业类型">{{
          info.XY010115
        }}</el-descriptions-item>
        <el-descriptions-item v-if="info.XY010119 == null" label="营业期限"
          >{{ info.XY010118 }} 至 无固定期限</el-descriptions-item
        >
        <el-descriptions-item v-if="info.XY010119 != null" label="营业期限"
          >{{ info.XY010118 }} 至 {{ info.XY010119 }}</el-descriptions-item
        >
        <el-descriptions-item label="纳税人资质">--</el-descriptions-item>
        <el-descriptions-item label="所属行业">{{
          info.XY010123
        }}</el-descriptions-item>
        <el-descriptions-item label="所属地区">--</el-descriptions-item>
        <el-descriptions-item label="登记机关">{{
          info.XY010103
        }}</el-descriptions-item>
        <el-descriptions-item :span="3" label="注册地址">{{
          info.XY010104
        }}</el-descriptions-item>
        <el-descriptions-item :span="3" label="经营范围">{{
          info.XY010116
        }}</el-descriptions-item>
      </el-descriptions>
    </div>

    <div id="stockholder">
      <p class="title-text">股东信息 {{ outPersonListTotal }}</p>
      <el-table
        :data="outPersonList"
        :header-cell-style="{ background: 'rgba(18,139,237,0.10)' }"
        style="width: 100%"
      >
        <el-table-column
          align="center"
          label="序号"
          type="index"
        ></el-table-column>
        <el-table-column
          align="left"
          label="股东名称"
          prop="XY010902"
          min-width="240"
        >
          <template slot-scope="scope">
            <div>
              <span class="xing">{{ scope.row.outXing }}</span>
              <span style="margin-left: 10px">{{ scope.row.XY010902 }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="持股比例" prop="" min-width="120"
          >--</el-table-column
        >
        <el-table-column
          align="center"
          label="认缴出资额(万元)"
          prop="XY010905"
          min-width="160"
        ></el-table-column>
        <el-table-column
          align="center"
          label="认缴出资日期"
          prop="XY010907"
          min-width="140"
        ></el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="outPersonListPageNo"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="outPersonListPagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="outPersonListTotal"
      ></el-pagination>
    </div>

    <div id="keyPerson">
      <p class="title-text">主要人员 {{ mainListTotal }}</p>
      <el-table
        :data="mainList"
        :header-cell-style="{ background: 'rgba(18,139,237,0.10)' }"
        style="width: 100%"
      >
        <el-table-column
          align="center"
          label="序号"
          type="index"
        ></el-table-column>
        <el-table-column align="center" label="姓名" prop="XY011001">
          <template slot-scope="scope">
            <div>
              <span style="float: left; margin-left: 2rem" class="xing">{{
                scope.row.mainXing
              }}</span>
              <span style="margin-left: 2rem">{{ scope.row.XY011001 }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="职务"
          prop="XY011002"
        ></el-table-column>
        <el-table-column align="center" label="持股比例" prop=""
          >--</el-table-column
        >
        <el-table-column align="center" label="最终受益" prop=""
          >--</el-table-column
        >
      </el-table>
      <el-pagination
        @size-change="handleMainListSizeChange"
        @current-change="handleMainListCurrentChange"
        :current-page="mainListPageNo"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="mainListPagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="mainListTotal"
      ></el-pagination>
    </div>

    <div id="change">
      <p class="title-text">变更记录 {{ changeListTotal }}</p>
      <el-table
        :data="changeList"
        :header-cell-style="{ background: 'rgba(18,139,237,0.10)' }"
        style="width: 100%"
      >
        <el-table-column
          align="center"
          label="序号"
          type="index"
        ></el-table-column>
        <el-table-column
          align="center"
          label="变更日期"
          prop="XY010802"
        ></el-table-column>
        <el-table-column
          align="center"
          label="变更项目"
          prop="XY010801"
        ></el-table-column>
        <el-table-column
          align="center"
          width="500px"
          label="变更前"
          prop="XY010803"
        ></el-table-column>
        <el-table-column
          align="center"
          width="400px"
          label="变更后"
          prop="XY010804"
        ></el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleChangeListSizeChange"
        @current-change="handleChangeListCurrentChange"
        :current-page="changeListPageNo"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="changeListPagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="changeListTotal"
      ></el-pagination>
    </div>

    <div>
      <p class="title-text">新闻舆情 {{ newsList.length }}</p>
      <el-table
        :data="newsList"
        :header-cell-style="{ background: 'rgba(18,139,237,0.10)' }"
        style="width: 100%"
      >
        <el-table-column
          align="center"
          label="序号"
          type="index"
        ></el-table-column>
        <el-table-column
          align="center"
          label="舆情类型"
          prop=""
        ></el-table-column>
        <el-table-column align="center" label="来源" prop=""></el-table-column>
        <el-table-column
          align="center"
          label="详细地址"
          prop=""
        ></el-table-column>
        <el-table-column
          align="center"
          label="关联公司"
          prop=""
        ></el-table-column>
        <el-table-column
          align="center"
          label="发布时间"
          prop=""
        ></el-table-column>
      </el-table>
    </div>

    <div>
      <p class="title-text">招聘 {{ recruitingList.length }}</p>
      <el-table
        :data="recruitingList"
        :header-cell-style="{ background: 'rgba(18,139,237,0.10)' }"
        style="width: 100%"
      >
        <el-table-column
          align="center"
          label="序号"
          type="index"
        ></el-table-column>
        <el-table-column
          align="center"
          label="招聘职位"
          prop=""
        ></el-table-column>
        <el-table-column
          align="center"
          label="招聘区域"
          prop=""
        ></el-table-column>
        <el-table-column
          align="center"
          label="发布时间"
          prop=""
        ></el-table-column>
        <el-table-column
          align="center"
          label="工作年限"
          prop=""
        ></el-table-column>
        <el-table-column align="center" label="学历" prop=""></el-table-column>
        <el-table-column
          align="center"
          label="招聘薪酬"
          prop=""
        ></el-table-column>
      </el-table>
    </div>

    <div>
      <p class="title-text">最终受益人 {{ beneficiaryList.length }}</p>
      <el-table
        :data="beneficiaryList"
        :header-cell-style="{ background: 'rgba(18,139,237,0.10)' }"
        style="width: 100%"
      >
        <el-table-column
          align="center"
          label="序号"
          type="index"
        ></el-table-column>
        <el-table-column
          align="center"
          label="受益人"
          prop=""
        ></el-table-column>
      </el-table>
    </div>

    <div>
      <p class="title-text">对外投资 {{ investmentList.length }}</p>
      <el-table
        :data="investmentList"
        :header-cell-style="{ background: 'rgba(18,139,237,0.10)' }"
        style="width: 100%"
      >
        <el-table-column
          align="center"
          label="序号"
          type="index"
        ></el-table-column>
        <el-table-column
          align="center"
          label="对外投资主体名称"
          prop=""
        ></el-table-column>
        <el-table-column
          align="center"
          label="法定代表人"
          prop=""
        ></el-table-column>
        <el-table-column
          align="center"
          label="注册资本"
          prop=""
        ></el-table-column>
        <el-table-column
          align="center"
          label="认缴出资额"
          prop=""
        ></el-table-column>
        <el-table-column
          align="center"
          label="持股比例"
          prop=""
        ></el-table-column>
        <el-table-column
          align="center"
          label="成立日期"
          prop=""
        ></el-table-column>
        <el-table-column
          align="center"
          label="经营状态"
          prop=""
        ></el-table-column>
        <el-table-column
          align="center"
          label="所属省份"
          prop=""
        ></el-table-column>
        <el-table-column
          align="center"
          label="所属行业"
          prop=""
        ></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "companyInfo",
  props: {
    sendInfo: Object,
  },
  data() {
    return {
      outPersonListTotal: 0,
      outPersonListPagesize: 10,
      outPersonListPageNo: 1,
      mainListTotal: 0,
      mainListPagesize: 10,
      mainListPageNo: 1,

      changeListPageNo: 1,
      changeListTotal: 0,
      changeListPagesize: 10,

      info: {},
      xing: "",
      mainList: [],
      changeList: [],
      outPersonList: [],
      newsList: [],
      recruitingList: [],
      corporationList: [],
      beneficiaryList: [],
      investmentList: [],
    };
  },
  created() {
    this.getInfo();
    this.getMainInfo();
    this.getChangeList();
    this.getOutPerson();
  },
  methods: {
    handleCurrentChange(res) {
      //分页当前页
      this.outPersonListPageNo = res;
      this.getOutPerson();
    },
    handleSizeChange(res) {
      //分页单页大小变化时
      this.outPersonListPagesize = res;
      this.getOutPerson();
    },
    handleMainListCurrentChange(res) {
      //分页当前页主要人员
      this.mainListPageNo = res;
      this.getMainInfo();
    },
    handleMainListSizeChange(res) {
      //分页单页大小变化时主要人员
      this.mainListPagesize = res;
      this.getMainInfo();
    },
    handleChangeListCurrentChange(res) {
      //分页当前页变更记录
      this.changeListPageNo = res;
      this.getChangeList();
    },
    handleChangeListSizeChange(res) {
      //分页单页大小变化时变更记录
      this.changeListPagesize = res;
      this.getChangeList();
    },

    getInfo() {
      this.info = this.sendInfo;
      // console.log(this.sendInfo,this.info,'this.info')
      this.xing = this.info.XY010105 ? this.info.XY010105.charAt(0) : "--";
      if (this.info.XY010117) {
        this.info.XY010117 = this.info.XY010117.substring(0, 10);
      }
      if (this.info.XY010118) {
        this.info.XY010118 = this.info.XY010118.substring(0, 10);
      }

      if (this.info.XY010134) {
        this.info.XY010134 = this.info.XY010134.substring(0, 10);
      }
      // console.log(this.info)
    },
    getMainInfo() {
      // console.log('/search/GB0110')
      let data = {
        socialCreditCode: this.info.XY010133,
        pageSize: this.mainListPagesize,
        pageNo: this.mainListPageNo,
      };
      this.$axios.post("/search/GB0110", data).then((res) => {
        // console.log(res,'resssss')
        if (res.data.code == 200) {
          this.mainList = res.data.data.data;
          this.mainListTotal = res.data.data.total;
          this.mainList.forEach((item, index) => {
            item.mainXing = item.XY011001 ? item.XY011001.charAt(0) : "--";
          });
        }
      });
    },
    getChangeList() {
      let data = {
        socialCreditCode: this.info.XY010133,
        pageSize: this.changeListPagesize,
        pageNo: this.changeListPageNo,
      };
      this.$axios.post("/search/GB0108", data).then((res) => {
        if (res.data.code == 200) {
          this.changeList = res.data.data.data;
          this.changeListTotal = res.data.data.total;
          // let arr = [];
          // let num = '';
          // let year = 0;
          // this.changeList.forEach((item, index) => {
          //     if(item.XY010802){//例：'05-11月-16'
          //         arr = item.XY010802.substring(0, 10).split(' ').join('').split('-');
          //         num = arr[1].substring(0,arr[1].indexOf('月'))
          //         if(num<10){
          //             num = '0'+num
          //         }
          //         if(arr[2]>60){
          //             year = '19'+arr[2]
          //         }else {
          //             year = '20'+arr[2]
          //         }
          //         item.XY010802 = year+'-'+num+'-'+arr[0]
          //     }else {
          //         item.XY010802 = item.XY010802
          //     }
          // })
        }
      });
    },
    getOutPerson() {
      let data = {
        socialCreditCode: this.info.XY010133,
        pageSize: this.outPersonListPagesize,
        pageNo: this.outPersonListPageNo,
      };
      this.$axios.post("/search/GB0109", data).then((res) => {
        if (res.data.code == 200) {
          this.outPersonList = res.data.data.data;
          this.outPersonListTotal = res.data.data.total;
          // console.log(this.outPersonList)
          this.outPersonList.forEach((item, index) => {
            if (item.XY010902) {
              item.outXing = item.XY010902.substring(0, 2);
            }
            if (item.XY010907) {
              item.XY010907 = item.XY010907.substring(0, 10);
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.lawsuit-card-container {
  .menu-item {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #424242;
    letter-spacing: 0;
    font-weight: 400;
    width: 96px;
    height: 42px;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      color: rgba(18, 139, 237);
    }
  }

  .xing {
    display: inline-block;
    width: 40px;
    height: 40px;
    background-color: #feb300;
    color: #ffffff;
    text-align: center;
    line-height: 40px;
    border-radius: 4px;
  }

  ::v-deep .el-descriptions-item__label.is-bordered-label.my-label {
    background: rgba(18, 139, 237, 0.1);
    width: 150px;
  }
  .legal-person-wrapper {
    display: flex;
    width: 242px;
    box-sizing: border-box;
    .yellow-color {
      width: 40px;
      height: 40px;
      background: #feb300;
      border-radius: 4px;
      color: #ffffff;
      text-align: center;
      font-size: 24px;
      font-weight: 400;
    }
    .legal-person-name {
      p {
        margin: 0px 10px;
        font-size: 14px;
        color: #424242;
      }
      .person-position {
        font-size: 14px;
        color: #8a8a8a;
      }
    }
  }

  .title-text {
    font-size: 14px;
    color: rgb(0, 0, 0);
    font-weight: 600;
    padding: 0px 10px;
  }
  ::v-deep .el-pagination {
    text-align: right;
  }
}
</style>
