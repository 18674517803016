<template>
  <div
    class="home-page-wrapper"
    v-loading.fullscreen.lock="loading"
    :element-loading-text="tipText"
  >
    <maskLayer
      :mask="mask"
      class="maskLayer"
      v-if="showmaskLayer"
      @maskClick="maskClick"
    ></maskLayer>
    <div class="top-header" style="border-bottom: solid 1px #eeeeee">
      <div>
        <div class="platform-wrapper" style="cursor: pointer">
          <img
            @click="goShouYe"
            src="../../../src/assets/xinjiangImage/service-platform-img.png"
            alt=""
          />
        </div>
        <div class="search-result-wrapper">
          <el-input
            size="medium"
            placeholder="请输入企业名称"
            v-model="search.companyName"
          >
            <el-button @click="searchQiYe" slot="append">查一下</el-button>
          </el-input>
        </div>
        <div>
          <div class="login-or-register">
            <div class="login-register-btn" v-if="headerShow">
              <el-button @click="login">登录&nbsp;/&nbsp;注册</el-button>
            </div>
            <el-dropdown
              v-else
              @command="handleCommand"
              class="login-register-btn"
            >
              <img
                v-if="userData.headImg == null"
                @click="goUser"
                style="width: 64px; height: 64px; cursor: pointer"
                src="../../../public/static/image/avatar.png"
              />
              <img
                v-if="userData.headImg != null"
                @click="goUser"
                style="width: 64px; height: 64px; cursor: pointer"
                :src="this.$getImageUrl(userData.headImg)"
                :onerror="this.$defaultimg"
              />
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>

    <div class="lawsuit-company-info-wrapper">
      <div>
        <el-card shadow="hover">
          <div class="lawsuit-company-info">
            <div>
              <img
                class="lawsuit-company-info-img"
                src="../../../public/static/image/gongsilogo.png"
              />
            </div>
            <div class="lawsuit-company-info-text-wrapper">
              <div class="lawsuit-top-text">
                <p>{{ info.XY010101 }}</p>
                <div class="elbutton">
                  <el-button
                    style="margin-left: 135px"
                    class="elbuttonO"
                    size="small"
                    icon="el-icon-bangzhu"
                    @click="goDoload"
                    >关联关系图谱</el-button
                  >
                  <el-popover
                    v-model="isPopover"
                    popper-class="popper-report"
                    placement="bottom"
                    width="400"
                    trigger="click"
                  >
                    <div
                      class="report"
                      v-for="val in printPDFList"
                      :key="val.value"
                      @click="openReport(val)"
                    >
                      {{ val.label }}
                    </div>
                    <el-button slot="reference" size="small"
                      ><span style="margin-right: 40px">信用报告下载</span>
                      <i class="el-icon-arrow-down"></i
                    ></el-button>
                  </el-popover>
                  <!-- <el-button
                    @click="downloadBasicReport"
                    class="elbuttonT"
                    :style="{ zIndex: zIndex }"
                    size="small"
                    icon="el-icon-document"
                    >信用报告基础版
                  </el-button> -->
                  <!--                  <el-button size="small" icon="el-icon-document" @click="checkReprot2">信用报告精进版</el-button>-->
                </div>
              </div>
              <div class="newdiv">
                <div class="newleft">
                  <div class="tag-group">
                    <div v-if="tagLists.length > 0">
                      <template v-for="item in tagLists">
                        <el-tag
                          v-if="!!item.tagName"
                          :key="item.tagName"
                          :type="item.tagType"
                          color=""
                          size="small"
                          effect="dark"
                        >
                          {{ item.tagName }}
                        </el-tag>
                      </template>
                    </div>
                    <!-- <div
                      style="width: 120px; height: 15px"
                      v-loading="true"
                      v-else
                      element-loading-text="企业标签加载中"
                      element-loading-spinner="el-icon-loading"
                    ></div> -->
                  </div>

                  <el-descriptions>
                    <el-descriptions-item label="法定代表人"
                      ><span class="text-color">{{
                        info.XY010105
                      }}</span></el-descriptions-item
                    >
                    <el-descriptions-item label="统一社会信用代码">{{
                      info.XY010133 ? info.XY010133 : "--"
                    }}</el-descriptions-item>
                  </el-descriptions>
                  <el-descriptions>
                    <el-descriptions-item label="电话">{{
                      info.XY010121 ? info.XY010121 : "--"
                    }}</el-descriptions-item>
                    <el-descriptions-item label="官网"></el-descriptions-item>
                  </el-descriptions>
                  <el-descriptions>
                    <el-descriptions-item label="邮箱"></el-descriptions-item>
                    <el-descriptions-item label="地址">{{
                      info.XY010104 ? info.XY010104 : "--"
                    }}</el-descriptions-item>
                  </el-descriptions>
                </div>
                <!--                <div class="newright" :key="indexKey" v-if="isLogin">-->
                <div class="newright" :key="indexKey">
                  <div v-if="testUrl">
                    <vue-qr
                      :logoSrc="imageUrl"
                      :text="testUrl"
                      :size="200"
                    ></vue-qr>
                    <div class="tips">[微信扫码下载信用报告]</div>
                  </div>
                  <div
                    v-loading="true"
                    v-else
                    element-loading-text="报告下载二维码生成中"
                    element-loading-spinner="el-icon-loading"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </el-card>

        <div class="lawsuit-company-detail-info-wrapper">
          <el-tabs v-model="activeName">
            <el-tab-pane label="基本信息" name="first">
              <company :sendInfo="info"></company>
            </el-tab-pane>
            <el-tab-pane label="法律诉讼" name="second">
              <lawsuit :sendInfo="info"></lawsuit>
            </el-tab-pane>
            <el-tab-pane label="经营风险" name="third">
              <div class="fengXian">
                <div style="display: flex; align-items: center">
                  <p
                    style="
                      font-size: 14px;
                      color: #000;
                      font-weight: 600;
                      padding: 0 10px;
                    "
                  >
                    严重违法
                  </p>
                </div>
                <template>
                  <el-table
                    :data="serious"
                    :header-cell-style="{ background: 'rgba(18,139,237,0.10)' }"
                    style="width: 100%"
                  >
                    <el-table-column
                      align="center"
                      label="序号"
                      type="index"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="企业名称"
                      prop="XY010101"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="债权人(年报)"
                      prop="XY023601"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="债务人(年报)"
                      prop="XY023602"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="主债权种类(年报)"
                      prop="XY023603"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="主债权数额(万元)"
                      prop="XY023604"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="履行债务的期限(年报)"
                      prop="XY023605"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="保证的方式(年报)"
                      prop="XY023607"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="主债权种类(年报)"
                      prop="XY023603"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="年度"
                      prop="XY023609"
                    ></el-table-column>
                  </el-table>
                </template>

                <div style="display: flex; align-items: center">
                  <p
                    style="
                      font-size: 14px;
                      color: #000;
                      font-weight: 600;
                      padding: 0 10px;
                    "
                  >
                    动产抵押
                  </p>
                </div>
                <template>
                  <el-table
                    :data="mortgage"
                    :header-cell-style="{ background: 'rgba(18,139,237,0.10)' }"
                    style="width: 100%"
                  >
                    <el-table-column
                      align="center"
                      label="序号"
                      type="index"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="抵押编号"
                      prop="XY011101"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="登记日期"
                      prop="XY011102"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="登记机关"
                      prop="XY011103"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="被担保的主债权种类"
                      prop="XY011104"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="被担保的主债权数额"
                      prop="XY011105"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="被担保范围"
                      prop="XY011106"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="抵押物名称"
                      prop="XY011110"
                    ></el-table-column>
                  </el-table>
                </template>

                <div style="display: flex; align-items: center">
                  <p
                    style="
                      font-size: 14px;
                      color: #000;
                      font-weight: 600;
                      padding: 0 10px;
                    "
                  >
                    行政处罚
                  </p>
                </div>
                <template>
                  <el-table
                    :data="administration"
                    :header-cell-style="{ background: 'rgba(18,139,237,0.10)' }"
                    style="width: 100%"
                  >
                    <el-table-column
                      align="center"
                      label="序号"
                      type="index"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="处罚文号"
                      prop="cf_WSH"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="处罚事由"
                      min-width="200"
                      prop="cf_SY"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="处罚类型"
                      prop="cf_CFLB"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="处罚结果"
                      prop=""
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="处罚单位"
                      prop="cf_CFJG"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="处罚日期"
                      prop="cf_JDRQ"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="数据来源"
                      prop="cf_SJLY"
                    ></el-table-column>
                  </el-table>
                  <el-pagination
                    @size-change="handlexzcfSizeChange"
                    @current-change="handlexzcfCurrentChange"
                    :current-page="xzcfPageNo"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="xzcfPagesize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="xzcfTotal"
                  ></el-pagination>
                </template>

                <div style="display: flex; align-items: center">
                  <p
                    style="
                      font-size: 14px;
                      color: #000;
                      font-weight: 600;
                      padding: 0 10px;
                    "
                  >
                    环保处罚
                  </p>
                </div>
                <template>
                  <el-table
                    :data="protection"
                    :header-cell-style="{ background: 'rgba(18,139,237,0.10)' }"
                    style="width: 100%"
                  >
                    <el-table-column
                      align="center"
                      label="序号"
                      type="index"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="处罚文号"
                      prop="XY011101"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="处罚日期"
                      prop="XY011102"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="处罚机构"
                      prop="XY011103"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="处罚内容"
                      prop="XY011104"
                    ></el-table-column>
                  </el-table>
                </template>
              </div>
            </el-tab-pane>
            <el-tab-pane label="知识产权" name="fourth">
              <div>
                <div style="display: flex; align-items: center">
                  <p
                    style="
                      font-size: 16px;
                      color: #000;
                      font-weight: 600;
                      padding: 0 10px;
                    "
                  >
                    商标信息
                  </p>
                </div>
                <template>
                  <el-table
                    :data="tableData"
                    v-loading="sbxxLoading"
                    :header-cell-style="{ background: 'rgba(18,139,237,0.10)' }"
                    style="width: 100%"
                  >
                    <el-table-column
                      align="center"
                      label="序号"
                      type="index"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="商标图案"
                      prop="sb_name"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="商标名称"
                      prop="sb_name"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="商标状态"
                      prop="sb_shangbiaozhuangtai"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="申请/注册号"
                      prop="sb_zhucehao"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="申请日期"
                      prop="sb_shenqingshijian"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="国际分类"
                      prop="sb_shangbiaoleixing"
                    ></el-table-column>
                  </el-table>
                </template>
                <div
                  style="display: flex; align-items: center; margin-top: 20px"
                >
                  <p
                    style="
                      font-size: 16px;
                      color: #000;
                      font-weight: 600;
                      padding: 0 10px;
                    "
                  >
                    作品著作权
                  </p>
                </div>
                <template>
                  <el-table
                    :data="tableData1"
                    :header-cell-style="{ background: 'rgba(18,139,237,0.10)' }"
                    style="width: 100%"
                  >
                    <el-table-column
                      align="center"
                      label="序号"
                      type="index"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="作品名称"
                      prop="name"
                      width="240px"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="首次发表日期"
                      prop="startTime"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="创作完成日期	"
                      prop="endTime"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="登记号"
                      prop="num"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="登记日期"
                      prop="upTime"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="登记类别"
                      prop="type"
                    ></el-table-column>
                  </el-table>
                </template>

                <div
                  style="display: flex; align-items: center; margin-top: 20px"
                >
                  <p
                    style="
                      font-size: 16px;
                      color: #000;
                      font-weight: 600;
                      padding: 0 10px;
                    "
                  >
                    备案网站
                  </p>
                  <!-- <p style="color: #128bed; cursor: pointer; margin-left: 10px">
                    网站信息专项查询 >
                  </p> -->
                </div>
                <template>
                  <el-table
                    :data="tableData2"
                    :header-cell-style="{ background: 'rgba(18,139,237,0.10)' }"
                    style="width: 100%"
                  >
                    <el-table-column
                      align="center"
                      label="序号"
                      type="index"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="网站名称"
                      prop="name"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="网址"
                      prop="address"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="域名"
                      prop="yuMing"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="网站备案/许可证号"
                      prop="number"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="审核日期"
                      prop="startTime"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="公安备案"
                      prop="peace"
                    ></el-table-column>
                  </el-table>
                </template>

                <div
                  style="display: flex; align-items: center; margin-top: 20px"
                >
                  <p
                    style="
                      font-size: 16px;
                      color: #000;
                      font-weight: 600;
                      padding: 0 10px;
                    "
                  >
                    专利信息
                  </p>
                </div>
                <template>
                  <el-table
                    :data="patent"
                    v-loading="zlxxLoading"
                    :header-cell-style="{ background: 'rgba(18,139,237,0.10)' }"
                    style="width: 100%"
                  >
                    <el-table-column
                      align="center"
                      label="序号"
                      type="index"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="专利名称"
                      prop="zl_zhuanlimingcheng"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="申请日期"
                      prop="zl_shenqingriqi"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="公开号"
                      prop="zl_gonggaohao"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="公开日期"
                      prop="zl_gonggaoriqi"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="发明人"
                      prop="xy010101"
                    ></el-table-column>
                  </el-table>
                </template>

                <div
                  style="display: flex; align-items: center; margin-top: 20px"
                >
                  <p
                    style="
                      font-size: 16px;
                      color: #000;
                      font-weight: 600;
                      padding: 0 10px;
                    "
                  >
                    证书
                  </p>
                </div>
                <template>
                  <el-table
                    :data="credentials"
                    :header-cell-style="{ background: 'rgba(18,139,237,0.10)' }"
                    style="width: 100%"
                  >
                    <el-table-column
                      align="center"
                      label="序号"
                      type="index"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="证书名称"
                      prop="name"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="证书许可期限"
                      prop="address"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="证书号"
                      prop="yuMing"
                    ></el-table-column>
                  </el-table>
                </template>
              </div>
            </el-tab-pane>
            <el-tab-pane label="经营信息" name="fifth">
              <management :sendInfo="info"></management>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>

    <div class="contenti"></div>
    <div></div>
    <foot></foot>

    <dialogLogin v-if="dialogVisible" @closeDialog="dialogFun"></dialogLogin>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import company from "@/views/company-detail/companyInfo";
import lawsuit from "@/views/company-cards-components/lawsuit-card";
import maskLayer from "@/components/maskLayer/maskLayer";
import management from "@/views/management-info/management-info-tables";
import foot from "@/components/footer/homeFooter";
import dialogLogin from "@/components/dialogLogin/dialogLogin";
import pdf from "vue-pdf";
import {
  removeHeaderToken,
  getHeaderToken,
  getUserId,
  removeUserId,
} from "../../plugins/auth";
import vueQr from "vue-qr";
export default {
  name: "Home",
  components: {
    company,
    lawsuit,
    management,
    foot,
    dialogLogin,
    pdf,
    vueQr,
    maskLayer,
  },
  data() {
    return {
      tagLists: [],
      // isLogin: false,
      printPDFList: [
        { value: 1, label: "企业基础版信用报告" },
        { value: 2, label: "招投标信用报告" },
      ],
      isPopover: false,
      indexKey: 0,
      testUrl: "",
      imageUrl: require("../../assets/logot.png"),
      activeName: "first",
      zIndex: 9,
      showmaskLayer: false, //注册登录默认指引框
      mask: {
        title: "该企业信用报告点击此处即可下载!",
        showNext: false,
        maskTop: "180px",
        maskLeft: "1123px",
        messtop: "180px",
        messleft: "1143px",
      },
      xzcfTotal: 0,
      xzcfPagesize: 10,
      xzcfPageNo: 1,
      tipText: "",
      sbxxLoading: false,
      zlxxLoading: false,
      // imgserve:"http://61.185.238.218:25886/pic",
      SbNameOptions: [
        {
          dictName: "化学原料",
          dictValue: "01",
        },
        {
          dictName: "原料油漆",
          dictValue: "02",
        },
        {
          dictName: "化妆品",
          dictValue: "03",
        },
        {
          dictName: "燃料油脂",
          dictValue: "04",
        },
        {
          dictName: "医疗卫生",
          dictValue: "05",
        },
        {
          dictName: "五金金属",
          dictValue: "06",
        },
        {
          dictName: "机械设备",
          dictValue: "07",
        },
        {
          dictName: "手工器械",
          dictValue: "08",
        },
        {
          dictName: "数码",
          dictValue: "09",
        },
        {
          dictName: "医疗器械",
          dictValue: "10",
        },
        {
          dictName: "家电",
          dictValue: "11",
        },
        {
          dictName: "运输工具",
          dictValue: "12",
        },
        {
          dictName: "军火烟花",
          dictValue: "13",
        },
        {
          dictName: "珠宝钟表",
          dictValue: "14",
        },
        {
          dictName: "乐器",
          dictValue: "15",
        },
        {
          dictName: "文化用品",
          dictValue: "16",
        },
        {
          dictName: "橡胶制品",
          dictValue: "17",
        },
        {
          dictName: "皮具箱包",
          dictValue: "18",
        },
        {
          dictName: "建筑材料",
          dictValue: "19",
        },
        {
          dictName: "家具",
          dictValue: "20",
        },
        {
          dictName: "日用品",
          dictValue: "21",
        },
        {
          dictName: "绳网袋篷",
          dictValue: "22",
        },
        {
          dictName: "纺织纱线",
          dictValue: "23",
        },
        {
          dictName: "床上用品",
          dictValue: "24",
        },
        {
          dictName: "服装鞋帽",
          dictValue: "25",
        },
        {
          dictName: "花边拉链",
          dictValue: "26",
        },
        {
          dictName: "地毯席垫",
          dictValue: "27",
        },
        {
          dictName: "体育玩具",
          dictValue: "28",
        },
        {
          dictName: "干货油奶",
          dictValue: "29",
        },
        {
          dictName: "食品调味",
          dictValue: "30",
        },
        {
          dictName: "水果花木",
          dictValue: "31",
        },
        {
          dictName: "啤酒饮料",
          dictValue: "32",
        },
        {
          dictName: "酒",
          dictValue: "33",
        },
        {
          dictName: "烟草玩具",
          dictValue: "34",
        },
        {
          dictName: "广告贸易",
          dictValue: "35",
        },
        {
          dictName: "金融物管",
          dictValue: "36",
        },
        {
          dictName: "建筑修理",
          dictValue: "37",
        },
        {
          dictName: "通讯电信",
          dictValue: "38",
        },
        {
          dictName: "运输旅行",
          dictValue: "39",
        },
        {
          dictName: "材料加工",
          dictValue: "40",
        },
        {
          dictName: "教育娱乐",
          dictValue: "41",
        },
        {
          dictName: "科研服务",
          dictValue: "42",
        },
        {
          dictName: "餐饮酒店",
          dictValue: "43",
        },
        {
          dictName: "医疗园艺",
          dictValue: "44",
        },
        {
          dictName: "社会法律",
          dictValue: "45",
        },
      ],
      itemIndex: 0,
      tableData: [],
      tableData1: [],
      tableData2: [],
      headerShow: false,
      contract: "展开",
      userData: {},
      dialogVisible: false,
      info: {},
      warrant: [],
      serious: [],
      mortgage: [],
      search: {
        companyName: "",
      },
      administration: [],
      protection: [],
      patent: [],
      credentials: [],
      reportCode: 0,
      reportUrl: "",
      loading: false,
    };
  },
  watch: {
    // 监听showmaskLayer
    showmaskLayer: {
      handler(newName, oldName) {
        // console.log(newName,oldName,'------')
        if (newName) {
          let body = document.getElementsByTagName("body");
          // console.log(body,'99')
          body[0].setAttribute("style", "overflow :hidden");
          this.zIndex = 9;
        } else {
          let body = document.getElementsByTagName("body");
          // console.log(body,'99')
          body[0].setAttribute("style", "overflow :auto");
          this.zIndex = 1;
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState("user", { token: "token" }),
    ...mapGetters("user", { getToken: "getToken" }),
  },
  async created() {
    await this.getInfo();
    this.getEntTags(); // 获取企业特色标签
    await this.getLocal();
    // if (this.userData.id != null) {
    //   this.isLogin = true;
    // } else {
    //   this.isLogin = false;
    // }
    let maskLayer = JSON.parse(sessionStorage.getItem("maskLayer"));
    let maskLayerNum = JSON.parse(sessionStorage.getItem("maskLayerNum"));
    // console.log(maskLayer,maskLayerNum,this.testUrl,'maskLayer应该查3')
    if (maskLayer) {
      this.zIndex = 1;
      this.showmaskLayer = false;
    } else {
      if (maskLayerNum == 3) {
        this.showmaskLayer = true;
      }
      // 点击关闭 或者最后一步改为
      // sessionStorage.setItem('maskLayer',JSON.stringify(true))
    }

    this.getWarrantList();
    this.getSeriousList();
    this.getMortgageList();
    // this.getReport()
    this.getSbxx(); //商标信息
    this.getQxrZlxx(); //获取专利信息
    this.getXyzFxzcf(); //行政处罚信息
    await this.initPdf();
  },
  activated() {
    this.getLocal();
  },
  mounted() {},
  methods: {
    //企业特色标签
    getToday() {
      const today = new Date();
      return today.format("yyyy-MM-dd");
    },
    getEntTags() {
      /**
       * //高新技术企业-HTECH_CERT
      //TECH_SMC-科技型中小企业，
      //自治区重点实验室-STD_LABBUILD
       * // { type: "", label: "高新技术企业" },
        // { type: "info", label: "科技型中小企业" },
        // { type: "", label: "新疆自治区重点实验室" },
       */

      let tagsName = [
        { type: "HTECH_CERT", name: "高新技术企业" },
        { type: "TECH_SMC", name: "科技型中小企业" },
        { type: "STD_LABBUILD", name: "新疆自治区重点实验室" },
      ];
      tagsName.forEach((tagName, index) => {
        this.$axios
          .post(`/search/baseService/${tagName.type}`, {
            socialCreditCode: this.info.XY010133, //this.info.XY010133
          })
          .then((res) => {
            console.log(res, "标签");
            if (res && res.data && res.data.data && res.data.data.size > 0) {
              console.log(res.data.data.data, "res.data.data.data");
              this.tagLists[index] = res.data.data.data[0];
              this.tagLists[index].tagName = tagName.name;
              if (index == 0 || index == 1) {
                this.tagLists[index].tagType =
                  this.tagLists[index].yxq >= this.getToday() ? "" : "info";
              } else if (index == 2) {
                this.tagLists[index].yxq = this.getToday();
                this.tagLists[index].tagType =
                  this.tagLists[index].yxq >= this.getToday() ? "" : "info";
              }

              console.log(this.tagLists, "this.tagLists");
            } else {
              this.tagLists[index] = {};
            }
          });
      });
    },
    // 初始化下载pdf地址
    async initPdf() {
      let data = {
        enterpriseCode: this.info.XY010133,
        enterpriseName: this.info.XY010101,
        businessName: this.userData.businessName,
        orgCode: "orgCode",
        pdfLocation: "pdfLocation",
        queryData: "",
        userName: this.userData.username,
        userId: this.userData.id,
        auditStatus: this.userData.auditStatus,
      };
      let str = `orgCode=orgCode&pdfLocation=pdfLocation&enterpriseName=${this.info.XY010101}&enterpriseCode=${this.info.XY010133}&businessName=${this.userData.businessName}&userName=${this.userData.username}&userId=${this.userData.id}&auditStatus=${this.userData.auditStatus}&queryData=''`;
      await this.$axios
        .get("/bank/getCreditDataModePDFBasic?" + str)
        .then((res) => {
          // console.log(JSON.parse(JSON.stringify(res)),'res11')
          if (res && res.data) {
            this.testUrl = res.data;
            this.indexKey++;
          }

          // if(res.indexOf('pdf')>-1){
          //   this.testUrl=res
          // }
        });
    },
    // 打开报告
    openReport(val) {
      this.isPopover = false;
      console.log(this.info);
      switch (val.value) {
        case 1:
          this.downloadBasicReport();
          break;
        case 2:
          // this.downloadReport();
          this.$confirm(
            "该功能模块正在更新，敬请期待！如有咨询意愿，请拨打0991-3532758",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(() => {})
            .catch(() => {});
          break;

        default:
          break;
      }
    },
    // 点击指引框
    maskClick(v) {
      // console.log(v,'---')
      if (v.type == "close") {
        // 关闭指引框
        this.zIndex = 1;
        this.showmaskLayer = false;
        // sessionStorage.setItem('maskLayer',JSON.stringify(true))
        sessionStorage.setItem("maskLayerNum", JSON.stringify(4));
      }
    },
    getSbxx() {
      //获取商标信息
      let data = {
        // enterpriseName:this.info.XY010101,
        XY010133: this.info.XY010133,
      };

      this.sbxxLoading = true;
      this.$axios.post("/bank/OUTER_SBXX", data).then((res) => {
        this.sbxxLoading = false;
        if (res.data.code == 200) {
          if (res.data.data.rows) {
            this.tableData = res.data.data.rows;
            for (let i = 0; i < this.tableData.length; i++) {
              for (let j = 0; j < this.SbNameOptions.length; j++) {
                if (
                  this.tableData[i].categoryName ==
                  this.SbNameOptions[j].dictValue
                ) {
                  this.tableData[i].categoryName =
                    this.SbNameOptions[j].dictName;
                }
              }
            }
          } else {
            this.tableData = [];
          }
        } else {
          this.tableData = [];
        }
      });
    },

    getQxrZlxx() {
      //获取专利信息
      let data = {
        // enterpriseName:this.info.XY010101,
        XY010133: this.info.XY010133,
      };
      this.zlxxLoading = true;
      this.$axios.post("/bank/OUTER_ZLXX", data).then((res) => {
        this.zlxxLoading = false;
        if (res.data.code == 200) {
          if (res.data.data.rows) {
            this.patent = res.data.data.rows;
          } else {
            this.patent = [];
          }
        } else {
          this.patent = [];
        }
      });
    },
    getXyzFxzcf() {
      //获取行政处罚信息
      let data = {
        uniscId: this.info.XY010133,
      };

      this.$axios.post("/bank/xyzFxzcf", data).then((res) => {
        this.administration = res.data.data.rows;
        this.xzcfTotal = res.data.data.total;
      });
    },
    handlexzcfCurrentChange(res) {
      this.xzcfPageNo = res;
      this.getXyzFxzcf();
    },

    handlexzcfSizeChange(res) {
      this.xzcfPagesize = res;
      this.getXyzFxzcf();
    },
    getInfo() {
      this.info = JSON.parse(window.sessionStorage.getItem("obj"));
    },
    goDoload() {
      // window.open('http://36.7.144.13:57011/ahrm/CristalBallgraph.servlet?username=admin&channel=shuijingqiu&name=%E5%AE%89%E5%BE%BD%E7%9C%81%E5%BE%81%E4%BF%A1%E8%82%A1%E4%BB%BD%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8')
      // this.$router.push('/atlas')
      if (this.userData.id != null) {
        // if(this.userData.auditStatus == '2'){
        this.getInsertUsage("中小企业关联图谱");
        // window.open("/atlas");
        const routeUrl = this.$router.resolve({
          path: "/atlas",
          query: { entname: this.info.XY010101 },
        });
        window.open(routeUrl.href, "_blank");
      } else {
        this.dialogVisible = true;
      }
    },
    goUser() {
      this.$router.push("/usercenter");
    },
    async getLocal() {
      if (getHeaderToken()) {
        this.headerShow = false;
        await this.$axios
          .get("/user/annualById?id=" + getUserId())
          .then((res) => {
            this.userData = res.data.data;
          });
      } else {
        this.userData = { headImg: null };
        this.headerShow = true;
      }
    },
    goShouYe() {
      this.$router.push("/");
    },
    // contractText() {
    //     if (this.contract == '展开') {
    //         this.contract = '收起'
    //     } else if (this.contract == '收起') {
    //         this.contract = '展开'
    //     }
    // },
    login() {
      this.dialogVisible = true;
    },
    dialogFun(val) {
      this.dialogVisible = val;
      this.getLocal();
      //   this.getReport();
      // this.downloadBasicReport()
    },
    //   checkPdfReprot() {
    //             if (this.userData.id != null) {
    //                 window.open('http://129.204.145.139:8950/report.pdf')
    //             } else {

    //             }
    //         },
    // 使用
    getInsertUsage(productName) {
      let data = {
        socialCreditCode: this.info.XY010133,
        userName: this.userData.id,
        productName: productName,
      };
      this.$axios.post("/useProduct/insertUserProduct", data).then((res) => {});
    },
    downloadBasicReport() {
      if (this.userData.id != null) {
        this.getInsertUsage("企业信用报告");
        this.loading = true;
        this.tipText = "正在出具信用报告，请耐心等待";
        let data = {
          enterpriseCode: this.info.XY010133,
          enterpriseName: this.info.XY010101,
          businessName: this.userData.businessName,
          orgCode: "orgCode",
          pdfLocation: "pdfLocation",
          queryData: "",
          userName: this.userData.username,
          userId: this.userData.id,
          auditStatus: this.userData.auditStatus,
        };
        this.$axios
          .post("/bank/getCreditDataModePDFBasic", data)
          .then((res) => {
            this.loading = false;
            if (res.data.code == 200 || res.data.code == "0") {
              let authFile =
                "data:application/pdf;base64," + res.data.data.file;
              const a = document.createElement("a");
              a.download = this.info.XY010101 + "_基础版报告";
              a.href = authFile;
              document.body.appendChild(a);
              a.click();
            } else {
              this.$notify({
                title: "失败",
                message: res.data.msg,
                type: "warning",
              });
            }
          });
      } else {
        this.dialogVisible = true;
      }
    },
    downloadReport() {
      let data = {
        entName: this.info.XY010101,
        uniscId: this.info.XY010133,
        sourceChannel: "3",
        againFlag: "0",
      };
      this.tipText = "正在出具招投标信用报告，请耐心等待";
      this.loading = true;
      this.$axios.post("/credit/report/bid", data).then((res) => {
        this.loading = false;
        console.log(res.data);
        if (res.data.code == 200 || res.data.code == "0") {
          let authFile = "data:application/pdf;base64," + res.data.data.file;
          const a = document.createElement("a");
          a.download = this.info.XY010101 + "_招投标信用报告";
          a.href = authFile;
          document.body.appendChild(a);
          a.click();
        } else {
          this.$notify({
            title: "失败",
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },
    checkReprot2() {
      if (this.userData.id != null) {
        this.loading = true;
        this.tipText = "正在出具信用报告，请耐心等待";
        let data = {
          enterpriseCode: this.info.XY010133,
          enterpriseName: this.info.XY010101,
          businessName: this.userData.businessName,
          orgCode: "orgCode",
          pdfLocation: "pdfLocation",
          queryData: "",
          userName: this.userData.username,
          userId: this.userData.id,
          auditStatus: this.userData.auditStatus,
        };

        this.$axios.post("/bank/getCreditDataModePDF", data).then((res) => {
          this.loading = false;
          if (res.data.code == 200 || res.data.code == "0") {
            let authFile = "data:application/pdf;base64," + res.data.data.file;
            const a = document.createElement("a");
            a.download = this.info.XY010101 + "_增值版报告";
            a.href = authFile;
            document.body.appendChild(a);
            a.click();
          } else {
            this.$notify({
              title: "失败",
              message: res.data.msg,
              type: "warning",
            });
          }
        });
      } else {
        this.dialogVisible = true;
      }
    },

    getWarrantList() {
      let data = {
        socialCreditCode: this.info.XY010133,
      };
      this.$axios.post("/search/GB0236", data).then((res) => {
        if (res.data.code == 200) {
          this.warrant = res.data.data.data;
        } else {
          this.warrant = [];
        }
      });
    },
    getSeriousList() {
      let data = {
        socialCreditCode: this.info.XY010133,
      };
      this.$axios.post("/search/GB0232", data).then((res) => {
        if (res.data.code == 200) {
          this.serious = res.data.data.data;
        } else {
          this.serious = [];
        }
      });
    },
    getMortgageList() {
      let data = {
        socialCreditCode: this.info.XY010133,
      };
      this.$axios.post("/search/GB0111", data).then((res) => {
        if (res.data.code == 200) {
          this.mortgage = res.data.data.data;
        }
      });
    },
    searchQiYe() {
      if (this.search.companyName == "") {
        this.$message("请输入需要查询的关键字");
      } else {
        this.loading = true;
        this.tipText = "";
        this.$axios.post("/search/business", this.search).then((res) => {
          if (res.data.code == 200 && res.data.data.code == 200) {
            let searchResult = JSON.stringify(res.data.data);
            window.sessionStorage.setItem("searchResult", searchResult);
            this.loading = false;
            this.$router.push({
              path: "/company",
              query: { searchKeyword: this.search.companyName },
            });
          }
        });
      }
    },

    //退出登录
    handleCommand() {
      this.$confirm("您确认退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.$message({
            type: "success",
            message: "退出成功!",
          });
          removeHeaderToken();
          removeUserId();
          this.getLocal();
        })
        .catch((err) => {
          this.$message({
            type: "info",
            message: "已取消退出",
          });
        });
    },
    //获取企业信用报告
    getReport(type) {
      return new Promise((reslove) => {
        if (this.userData.id != null) {
          let data = {
            socialCreditCode: this.info.XY010133,
            userId: this.userData.id,
          };
          let url = "";
          if (type == 2) {
            // 精进版信用报告
            url = "/search/advancedCreditReport";
          } else {
            // 基础版信用报告
            url = "/search/enterpriseCreditReport";
          }
          this.loading = true;
          this.$axios.post(url, data).then((res) => {
            if (res.data.code == 200) {
              this.reportCode = 200;
              this.reportUrl = res.data.link;
            } else {
              this.reportCode = res.data.code;
              this.reportUrl = "";
              this.$notify({
                title: "失败",
                message: res.data.msg,
                type: "error",
              });
            }
            this.loading = false;
            reslove();
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-button--small {
  padding: 9px 10px !important ;
}
.report {
  text-align: left;
  height: 25px;
  line-height: 25px;
  font-size: 12px;
  &:hover {
    cursor: pointer;
  }
}
</style>

<style lang="less">
.popper-report {
  width: 135px !important;
  padding: 5px;
  .popper__arrow {
    display: none;
  }
  margin: 0 !important;
  min-width: 135px !important;
}
.lawsuit-company-info-wrapper {
  min-width: 1200px;
  padding: 40px 0;
  & > div {
    width: 1200px;
    margin: 0 auto;
    .lawsuit-company-info {
      display: flex;
      .lawsuit-company-info-img {
        width: 60px;
        height: 60px;
      }
      .lawsuit-company-info-text-wrapper {
        padding: 0 20px;
        .lawsuit-top-text {
          display: flex;
          justify-content: space-between;
          .elbutton {
            position: relative;
            .elbuttonO {
              position: absolute;
              left: -260px;
            }
            .elbuttonT {
              position: absolute;
              left: -135px;
            }
          }
          & > p {
            font-family: PingFangSC-Medium;
            font-size: 22px;
            letter-spacing: 0;
            font-weight: 700;
            margin-top: 0px;
          }
        }
        .tag-group {
          margin-bottom: 12px;
          .el-tag {
            margin-right: 10px;
          }
        }
        .text-color {
          color: rgba(18, 139, 237);
          font-weight: bold;
        }
        .money-wrapper {
          color: rgba(102, 102, 102);
          font-weight: bold;
        }
        .el-descriptions-item__container .el-descriptions-item__label {
          color: #78909c;
        }
      }
    }

    .lawsuit-company-detail-info-wrapper {
      padding: 20px;
      background: #ffffff;
      margin-top: 20px;
      .el-tabs__item {
        padding: 0 100px;
      }
    }
  }
}

.fengXian {
  width: 100%;

  img {
    width: 190px;
    height: 190px;
    position: relative;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  p {
    display: block;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    text-align: center;
    margin-top: 30px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 14px;
  }
}
.newdiv {
  display: flex;
  align-items: flex-start;
  .newleft {
    flex: 8;
  }
  .newright {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    > div {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .tips {
      color: #999;
      font-size: 12px;
    }
  }
}
</style>
